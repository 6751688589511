import React, { useState, useEffect } from 'react';
import HeaderBar from '../../components/header-bar';

import IconCrossBlack from '../../images/icons/i-cross-black.svg';

import { navigate } from 'gatsby';
import { useAPI } from '../../utils/api';
import { callAppFunc } from '../../utils/jsbridge/index.js';
import { useSelector } from 'react-redux';
import store, { actions, getters } from '../../redux/store';
import { setHotSearchTag, setSelectProductList } from '../../utils/_ga';
import { pageCheck } from '../../utils/pageCheck';

const RecentlyItem = ({ item, index, onDelete, goNext }) => (
  <>
    <li
      className="recently-item"
      onClick={(event) => {
        event.stopPropagation();
        goNext(item);
      }}
    >
      {item}
      <img
        className="delete-item"
        src={IconCrossBlack}
        alt="delete"
        onClick={(event) => {
          event.stopPropagation();
          onDelete(index);
        }}
      />
    </li>

    <style jsx>
      {`
        .recently-item {
          padding: 9px 0px;
          font-family: Noto Sans TC;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          color: #333333;

          display: flex;
          justify-content: space-between;
        }

        .delete-item {
          width: 12px;
          height: 12px;
        }
      `}
    </style>
  </>
);

const PopularItem = ({ name, goNext }) => (
  <>
    <div
      className="popular-item"
      onClick={() => {
        goNext(name);
      }}
    >
      {name}
    </div>

    <style jsx>
      {`
        .popular-item {
          background: #ffffff;
          border: 1px solid #e3e3e3;
          box-sizing: border-box;
          border-radius: 30px;
          display: inline-block;
          padding: 4px 8px;
          margin: 0 8px 8px 0;

          font-family: Noto Sans TC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          text-align: center;

          color: #3b3516;
        }
      `}
    </style>
  </>
);

const CategoryItem = ({ data, goNext }) => (
  <>
    <div
      className="category-item"
      onClick={() => {
        goNext(data.id);
      }}
    >
      <div className="category-icon">
        <img src={data.image} />
      </div>

      <div className="category-name">{data.name}</div>
    </div>

    <style jsx>{`
      .category-item {
        text-align: center;
        padding: 5px 0;
        background: #ffffff;
        border-radius: 10px;

        // .category-icon {
        //   margin-bottom: 8px;
        // }

        .category-icon {
          margin-bottom: 8px;
          width: 52.3%;
          height: 0;
          padding-bottom: 52.3%;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }

        .category-name {
          font-family: Noto Sans TC;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          color: #3b3516;
        }
      }
    `}</style>
  </>
);

const searchKey = '';

const Search = () => {
  const api = useAPI();
  const [searchArr, setSearch] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const currentStoreId = useSelector((state) => getters.getStoreId(state));

  const pageName = '商品搜尋頁';

  useEffect(() => {
    const searchJsonString = localStorage.getItem('search');
    if (searchJsonString !== null) {
      const searchJson = JSON.parse(searchJsonString);
      setSearch(searchJson);
    }

    getCategories();
    getTags();
  }, []);

  const getCategories = () => {
    api
      .getCategories(currentStoreId)
      .then((res) => {
        if (!res) {
          callAppFunc('storeClose', {});
          return;
        }
        if (res.length > 0) {
          setCategories(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTags = () => {
    api
      .getTags()
      .then((res) => {
        if (res.length > 0) {
          setTags(res);
        } else {
          setTags([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onDelete = (index) => {
    const newArr = [];
    var idx = 0;
    for (const element of searchArr) {
      if (index !== idx) newArr.push(element);
      idx++;
    }
    setSearch(newArr);
    const forSave = [];
    for (const element of newArr) {
      forSave.push(element);
    }
    localStorage.setItem('search', JSON.stringify(forSave.slice(0, 5)));
  };

  return (
    <>
      <HeaderBar
        search
        onSearchKeyChange={(e) => {
          console.log(e);
        }}
        goBack={() => {
          callAppFunc('backHome', {});
        }}
        onEnter={(searchKey) => {
          pageCheck(api, () => {
            const keyWord = encodeURI(searchKey);
            navigate(`/search/result/${keyWord}`, {
              state: {},
              replace: false,
            });
          });
        }}
      />
      <div className="search-page">
        <h2 className="section-title">最近搜尋的項目</h2>
        <ul className="recently-list">
          {searchArr &&
            searchArr.map((item, index) => (
              <RecentlyItem
                key={index}
                item={item}
                index={index}
                onDelete={(index) => {
                  onDelete(index);
                }}
                goNext={(name) => {
                  pageCheck(api, () => {
                    navigate(`/search/result/${name}`, {
                      state: {},
                      replace: false,
                    });
                  });
                }}
              />
            ))}
        </ul>

        <h2 className="section-title">熱門搜尋</h2>
        <div className="popular-list">
          {tags &&
            tags.map((item, index) => (
              <PopularItem
                name={item}
                key={index}
                goNext={(name) => {
                  setHotSearchTag(name, pageName);
                  pageCheck(api, () => {
                    navigate(`/search/result/${name}`, {
                      state: {},
                      replace: false,
                    });
                  });
                }}
              />
            ))}
        </div>

        <h2 className="section-title">商品分類</h2>
        <div className="category-list">
          {categories &&
            categories.map((item, index) => (
              <CategoryItem
                key={index}
                data={item}
                goNext={(id) => {
                  setSelectProductList(item.id, item.name, pageName);
                  pageCheck(api, () => {
                    navigate(`/category/product/${id}`, {
                      state: {},
                      replace: false,
                    });
                  });
                }}
              />
            ))}
        </div>
      </div>

      <style jsx>
        {`
          .search-page {
            background-color: #f2f2f2;
            padding: 16px;
            .section-title {
              margin-bottom: 12px;
              color: #333333;
              font-family: Noto Sans TC;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 26px;
            }

            .recently-list {
              margin-bottom: 20px;
            }

            .popular-list {
            }

            .category-list {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              row-gap: 8px;
              column-gap: 8px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Search;
